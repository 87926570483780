import React from 'react';

import type { ExtensionParams } from '@atlaskit/editor-common/extensions';

import { RENDERER, getExperienceName } from '@confluence/macro-tracker';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { usePageSpaceKey } from '@confluence/page-context';

import { CustomSitesRenderedExtension } from '../../shared-components/CustomSitesRenderedExtension';
import type { LinkCardsParameters } from '../linkCardsTypes';
import { LinkCardsContent } from '../LinkCardsContent';

type LinkCardsContainerProps = {
	extensionNode: ExtensionParams<LinkCardsParameters>;
	contentId: string;
};

export const LinkCards = ({ extensionNode, contentId }: LinkCardsContainerProps) => {
	const [spaceKey] = usePageSpaceKey();
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);

	const { parameters, localId = '' } = extensionNode;
	if (!parameters) {
		return null;
	}

	const experienceName = getExperienceName(RENDERER, extensionNode);

	return (
		<CustomSitesRenderedExtension
			extensionNode={extensionNode}
			contentId={contentId}
			analyticsAttributes={
				isCompanyHub
					? {
							hubSegementationApplied: parameters?.isSegmentationShown,
							renderVariant: parameters?.renderVariant,
						}
					: undefined
			}
		>
			<LinkCardsContent
				extensionLocalId={localId}
				parameters={parameters}
				isInViewMode
				contentId={contentId}
				experienceName={experienceName}
			/>
		</CustomSitesRenderedExtension>
	);
};
